.slick-dots {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -8px;
  margin: 0;
  padding: 1rem 0;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #cb0b36;
}
.slick-dots li button:before {
  color: #ffffff;
  font-size: 12px;
  height: 12px;
  line-height: 20px;
  opacity: 1;
  text-align: center;
  width: 12px;
}
.slider {
  /* width: 40rem; */
  position: relative;
}
.slick-list {
  border-radius: 0.5rem;
  text-align: -webkit-center;
}
.slick-track:before {
  border-radius: 0.5rem;
}
.slick-track::after {
  border-radius: 0.5rem;
}
img {
  border-radius: 0.5rem;
}
.slick-prev:before {
  color: #ce1c32;
  font-size: 34px;
  margin: 0 12px;
}
.slick-prev {
  z-index: 99;
}
.slick-next:before {
  color: #ce1c32;
  font-size: 34px;
  margin: 0 -22px;
}

.cardSlides .slick-slide > div {
  padding: 0 6px;
}
