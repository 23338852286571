html {
  scroll-behavior: smooth;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  font-family: Rubik;
}

.fill {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.sessionButton {
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: black;
  border-radius: 10px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 17px;
}

/* Styling the scrollbar track */
/* ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.9);
  border-radius: 5px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #d3114c;
} */

::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb {
  background-color: #c60a32;
  border-radius: 100px;
}
