body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f5f9 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Lines Seed";
  font-weight: 400; /* Regular font weight */
  font-style: normal;

  src: url("../src/assets/fonts/LINESeedSansTH_W_Rg.woff2") format("woff2");
}
@font-face {
  font-family: "Lines Seed";
  font-weight: 600; /* Bold font weight */
  font-style: normal;

  src: url("../src/assets/fonts/LINESeedSansTH_W_Bd.woff2") format("woff2");
}

@font-face {
  font-family: "Lines Seed";
  font-weight: 700; /* Extra Bold font weight */
  font-style: normal;

  src: url("../src/assets/fonts/LINESeedSansTH_W_XBd.woff2") format("woff2");
}

/* Provide fallback fonts */
* {
  font-family: "Lines Seed" !important;
}
